import React, { useContext } from "react";
import { useCallback, useState } from "react";
import { Marker, Layer, Source } from "react-map-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as turf from "@turf/turf";

import { DrawControl } from "../RightNavigationBar/draw-control";
import { MapContext } from "../MainMap";
import {
  getCircleMeasurement,
  getLineStringMeasurement,
  return3DecimalPoints,
} from "./drawToolFunctions";
import useMarker from "./hooks/useMarker";
import { debounce } from "lodash";

const geojson: any = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: { type: "Point", coordinates: [-122.4, 37.8] },
    },
  ],
};

const layerStyle: any = {
  id: "point",
  type: "fill",
  paint: {
    "fill-color": "#3bb2d0",
    "fill-opacity": 0.3,
  },
};

// const layerStyle: any = {
//   id: 'point',
//   type: 'circle',
//   paint: {
//     'circle-radius': feature.properties.circleRadius,
//     'circle-color': '#007cbf',
//     'circle-opacity': 0.5,
//     'circle-stroke-width': 1,
//     'circle-stroke-color': 'red'
//   }
// }

export interface FeatureProperties {
  markerPoint: number[];
  count: number;
  distance: number;
  radius: number;
  angle: number;
  area: number;
}

const DrawTools = () => {
  // set this feature as well, when we set it through reference
  const [drawToolMarker, setDrawToolMarker] = useState(null) as any;
  const {
    drawRef,
    showTemporaryCircle,
    setShowTemporaryCircle,
    features,
    setFeatures,
    showGlobeView,
  } = useContext(MapContext);

  const markerData: FeatureProperties[] = useMarker(features?.data);

  const onUpdate = debounce((e: any) => {
    setFeatures((currFeatures: any) => {
      const [activeFeature] = e.features
      const shape = currFeatures.data[activeFeature.id];
      const renderDrawMode = shape?.renderDrawMode;
      let measurements: any = {};

      if (renderDrawMode === "draw_line_string") {
        const allPoints = e.features[0].geometry.coordinates;
        const res = getLineStringMeasurement(allPoints);
        if (res) {
          measurements = res;
        }
      }

      if (renderDrawMode === "draw_circle") {
        const res = getCircleMeasurement(e.features[0]) as any;
        if (res) {
          measurements = res;
        }
      }

      if (
        renderDrawMode === "draw_rectangle" ||
        renderDrawMode === "draw_polygon"
      ) {
        const allPoints = e.features[0].geometry.coordinates[0];

        if (allPoints.length > 3) {
          let polygon = turf.polygon([[...allPoints, allPoints[0]]]);
          let area = return3DecimalPoints(turf.area(polygon) / 1000000);
          measurements = { area };
        }
      }

      const newFeatures: any = { ...currFeatures?.data };
      for (const f of e.features) {
        newFeatures[f.id] = {
          ...newFeatures[f.id],
          ...f,
          properties: { ...f.properties, ...measurements },
        };
      }
      return { ...currFeatures, data: newFeatures };
    });
    setDrawToolMarker(null);
  }, 100);

  const onCreate = (e: any) => {
    setShowTemporaryCircle(false);
    const renderDrawMode = drawRef.current.getMode();

    let measurements: any = {};
    if (renderDrawMode === "draw_line_string") {
      const allPoints = e.features[0].geometry.coordinates;
      const res = getLineStringMeasurement(allPoints) as any;
      if (res) {
        measurements = res;
      }
    }

    if (renderDrawMode === "draw_circle") {
      const res = getCircleMeasurement(e.features[0]) as any;
      if (res) {
        measurements = res;
      }
    }

    if (
      renderDrawMode === "draw_polygon" ||
      renderDrawMode === "draw_rectangle"
    ) {
      const allPoints = e.features[0].geometry.coordinates[0];

      if (allPoints.length > 3) {
        let polygon = turf.polygon([[...allPoints, allPoints[0]]]);
        let area = return3DecimalPoints(turf.area(polygon) / 1000000);
        measurements = { area };
      }
    }

    setFeatures((currFeatures: any) => {
      const newFeatures: any = { ...currFeatures?.data };
      const count =
        renderDrawMode === "draw_polygon" || renderDrawMode === "draw_rectangle"
          ? currFeatures.count + 1
          : null;
      for (const f of e.features) {
        newFeatures[f.id] = {
          ...f,
          renderDrawMode,
          properties: { ...f.properties, ...measurements, renderDrawMode },
          count: count,
        };
      }
      return { data: newFeatures, count: count ?? currFeatures.count };
    });
    setDrawToolMarker(null);
  };

  const onDelete = useCallback((e: any) => {
    setFeatures((currFeatures: any) => {
      const newFeatures: any = { ...currFeatures?.data };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return { ...currFeatures, data: newFeatures };
    });
  }, []);

  const showLiveMeasurement = (e: any, ao: any) => {
    const renderDrawMode = drawRef.current.getMode();
    const allFeatures = drawRef.current.getAll();

    if (renderDrawMode === "draw_line_string") {
      const allPoints =
        allFeatures.features[allFeatures.features.length - 1].geometry
          .coordinates;
      const res = getLineStringMeasurement(allPoints) as any;
      if (res) {
        const { distance, angle, latLng, area } = res;
        setDrawToolMarker({ distance, angle, latLng, area });
      }
    }

    if (renderDrawMode === "draw_circle" && allFeatures.features.length > 0) {
      const res = getCircleMeasurement(
        allFeatures.features[allFeatures.features.length - 1]
      ) as any;
      if (res) {
        const { radius, latLng } = res;
        setDrawToolMarker({ radius, latLng });
      }
    }

    if (
      renderDrawMode === "draw_polygon" ||
      renderDrawMode === "draw_rectangle"
    ) {
      const allPoints =
        allFeatures.features[allFeatures.features.length - 1].geometry
          .coordinates[0];

      if (allPoints.length > 3) {
        let polygon = turf.polygon([[...allPoints, allPoints[0]]]);
        let area = return3DecimalPoints(turf.area(polygon) / 1000000);
        setDrawToolMarker({ area, latLng: allPoints[allPoints.length - 1] });
      }
    }
  };

  const hiddenCircleSHowFeatures: any = [];

  if (features) {
    Object.keys(features.data).forEach((key: any) => {
      const feature = features.data[key];
      if (feature.properties.renderDrawMode === "draw_circle") {
        hiddenCircleSHowFeatures.push(feature.properties.circlePoints);
      }
    });
  }
  return (
    <div>
      {drawToolMarker &&
        !isNaN(drawToolMarker?.latLng[0]) &&
        !isNaN(drawToolMarker?.latLng[1]) && (
          <Marker
            longitude={drawToolMarker.latLng[0]}
            latitude={drawToolMarker.latLng[1]}
            anchor="top"
          >
            <div style={{ backgroundColor: "#000", padding: 10, margin: 20 }}>
              {drawToolMarker.distance && (
                <div>
                  {getRoundedValue(drawToolMarker.distance)}km: distance
                </div>
              )}
              {drawToolMarker.radius && (
                <div>{getRoundedValue(drawToolMarker.radius)}km: radius</div>
              )}
              {drawToolMarker.angle && (
                <div>{getRoundedValue(drawToolMarker.angle)}°: angle</div>
              )}
              {drawToolMarker.area && (
                <div>{drawToolMarker.area}km &#178;: area</div>
              )}
            </div>
          </Marker>
        )}

      {
        markerData && markerData?.map(feature => (
          <>
            {feature &&
              <Marker
                longitude={feature?.markerPoint?.[0] || 0}
                latitude={feature?.markerPoint?.[1] || 0}
                anchor="top"
              >
                <div
                  style={{ backgroundColor: "#000", padding: 10, margin: 20 }}
                >
                  {feature?.count && <div>Shape {feature.count}</div>}
                  {feature?.distance && (
                    <div>
                      {feature.distance}km: distance(last two points)
                    </div>
                  )}
                  {feature.radius && (
                    <div>{feature.radius}km: radius</div>
                  )}
                  {feature?.angle && (
                    <div>
                      {feature.angle}°: angle(last two
                      points)
                    </div>
                  )}
                  {feature.area&& (
                    <div>{feature.area}km &#178;: area</div>
                  )}
                </div>
              </Marker>
            }
          </>
        ))
      }
      <DrawControl
        ref={drawRef}
        position="top-left"
        displayControlsDefault={false}
        onRender={(e: any, ao: any) => showLiveMeasurement(e, ao)}
        defaultMode={"simple_select"}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
        key={showGlobeView}
      />

      {showTemporaryCircle && (
        <Source
          id="my-data"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: hiddenCircleSHowFeatures,
          }}
        >
          <Layer {...layerStyle} />
        </Source>
      )}
    </div>
  );
};

const getRoundedValue = (nonRoundedValue: any) => {
  return Math.round(nonRoundedValue * 100) / 100;
};

export default DrawTools;
