import { useContext, useEffect, useRef, useState } from "react";
import { MapContext, ShipDetailsInterFace } from "../MainMap";
import axios from "axios";
import { getShipInfo, getShipPath } from "../RenderShips/apiCalls";
import moment from "moment";
import { frontendAPIURL } from "../../../App";
interface SearchProps {
  initialSearchResultsState: any; // You can replace 'any' with a more specific type if possible
}

export interface ObjectSearchOptionResult {
  name?: string;
  acquired?: string;
  dark?: boolean;
  heading?: number;
  imo?: number;
  latitude?: number;
  length?: number;
  longitude?: number;
  mmsi?: number;
  moving?: boolean;
  object_id: string;
  provider?: string;
  ship_type?: string;
  spoofing?: any;
  status?: string;
  sts?: any;
  synmax_ship_id?: string;
  width?: number;
}

export interface VesselSearcOptionResult {
  synmax_ship_id: string;
  name?: string;
  call_sign?: string;
  imo?: string;
  length?: number;
  width?: number;
  mmsi: number;
  ship_type?: string;
  flag?: string;
  object_id?: string;
}

export enum FilterType {
  Vessel = "Vessels",
  Object = "Satellite Images",
}

export interface FilterOption {
  filterType: FilterType;
  value: string;
}

export const defaultOptions: FilterOption[] = [
  { filterType: FilterType.Vessel, value: "shipId" },
  { filterType: FilterType.Object, value: "objectId" },
];

export interface CurrentSearchResults {
  vessels: VesselSearcOptionResult[];
  objects: ObjectSearchOptionResult[];
  vesselsTotal: number;
  objectsTotal: number;
}

export const initialSearchResultsState: CurrentSearchResults = {
  vessels: [],
  objects: [],
  vesselsTotal: 0,
  objectsTotal: 0,
};

interface SatlleiteImageData {
  acquired: number
  aoi?: any,
  attribution: string;
  dark: boolean
  dark_time: number;
  heading?: number;
  longitude?: number;
  moving: boolean;
  object_id: boolean
  provider: string;
  ship_type: string;
  source_id: string;
  spoofing: boolean;
  status: string;
  sts: boolean;
  length: number;
  width?: number;
}

const SEARCH_OPTIONS_LIMIT = 5;


const useSearch = () => {
  const {
    setShipDetailTabValue,
    setSelectedShip,
    getUniqueColor,
    setShipPaths,
    updateActiveStatus,
    setSelectedEvent,
    onShipClick,
    setShipDetails,
    setMapViewState,
  } = useContext(MapContext);

  const [showResultsDialogIsOpen, setShowResultsDialogIsOpen] =
    useState<boolean>(false);

  const [loadingSpinnerIsShown, setLoadinSpinnerIsShown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterOption>(
    defaultOptions[0]
  );
  const [searchDropdownIsOpen, setSearchDropdownIsOpen] = useState(false);
  const [currentSearchValue, setCurrentSearchValue] = useState("");

  const searchTermRefValue = useRef<string | null>(null);
  const [currentSearchResults, setCurrentSearchResults] =
    useState<CurrentSearchResults>(initialSearchResultsState);

  useEffect(() => {
    setSelectedFilter(defaultOptions[0]);
  }, []);
  const token = localStorage.getItem("token");

  const handleVesselShipSelect = async (shipId: string) => {
    onShipClick();
    const res = await axios.post(
      `${frontendAPIURL}/live_ships`,
      { id: "frontend", shipids: [shipId] },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const { timestamp, latitude, longitude } = res.data[0];
    setMapViewState({ latitude, longitude, zoom: 6 });
    setShipDetailTabValue(shipId);
    setSelectedShip((prevShips: any) => ({
      ...prevShips,
      [shipId]: {},
    }));
    const shipDetails = await getShipInfo(
      [shipId],
      moment(timestamp * 1000).format("YYYY-MM-DD")
    );
    const startTimestamp = timestamp * 1000 - 24 * 60 * 60 * 1000;
    const startDate = `${moment
      .utc(startTimestamp)
      .format("YYYY-MM-DD HH:mm:ss")}`;
    const endDate = `${moment
      .utc(timestamp * 1000)
      .format("YYYY-MM-DD HH:mm:ss")}`;
    const shipPath = await getShipPath([shipId], startDate, endDate);
    const shipPathColor = getUniqueColor();
    setShipPaths((prev: any) => ({
      ...prev,
      [shipId]: {
        path: shipPath,
        color: shipPathColor,
        analysis: false,
        dateRange: { startDate, endDate },
        showShip: true,
      },
    }));
    const updatedShipDetails = {
      ...shipDetails,
      [shipId]: {
        ...res.data[0],
        ...shipDetails[shipId],
        type: "AIS",
        active: true,
      },
    };
    setSelectedShip((prevShips: any) => ({
      ...updateActiveStatus(prevShips, shipId),
      ...updatedShipDetails,
    }));
    setSelectedEvent((prevEvents: any) => ({
      ...prevEvents,
      ...updateActiveStatus(prevEvents, shipId),
    }));
  };

  const handleAttributedObjectSelect = async (data: SatlleiteImageData, attributionType: "light" | "attributed") => {
    onShipClick();
    const shipId = data.attribution;
    if (shipId) {
      setShipDetailTabValue(shipId);
      setSelectedShip((prevShips: any) => ({
        ...prevShips,
        [shipId]: {},
      }));
      const startTimestamp = data.acquired - 24 * 60 * 60 * 1000;
      const dateSting = moment.utc(data.acquired).format("YYYY-MM-DD");
      const shipDetails = await getShipInfo([shipId], dateSting);
      setShipDetails((prev: any) => ({
        ...prev,
        ...shipDetails,
      }));
      const startDate = `${moment
        .utc(startTimestamp)
        .format("YYYY-MM-DD HH:mm:ss")}`;
      const endDate = `${moment
        .utc(data.acquired)
        .format("YYYY-MM-DD HH:mm:ss")}`;
      const shipPath = await getShipPath([shipId], startDate, endDate);
      const shipPathColor = getUniqueColor();
      setShipPaths((prev: any) => ({
        ...prev,
        [shipId]: {
          path: shipPath,
          color: shipPathColor,
          analysis: false,
          dateRange: { startDate, endDate },
          showShip: true,
        },
      }));
      const updatedShipDetails = {
        ...shipDetails,
        [shipId]: {
          ...data,
          ...shipDetails[shipId],
          type: attributionType,
          active: true,
        },
      };
      setSelectedShip((prevShips: any) => ({
        ...updateActiveStatus(prevShips, shipId),
        ...updatedShipDetails,
      }));
      setSelectedEvent((prevEvents: any) => ({
        ...prevEvents,
        ...updateActiveStatus(prevEvents, shipId),
      }));
    }
  };

  const handleNoAttributionShipSelect = (data: SatlleiteImageData) => {
    onShipClick();
    const shipId = `unattributed-${data.object_id}`;
    setShipDetailTabValue(shipId);
    setSelectedShip((prevShips: any) => ({
      ...(prevShips as { [key: string]: ShipDetailsInterFace }),
      [shipId]: {} as ShipDetailsInterFace,
    }));
    const updatedShipDetails = {
      [shipId]: {
        ...data,
        synmax_ship_id: shipId,
        active: true,
        type: "unattributed",
      },
    };
    setSelectedShip((prevShips: any) => ({
      ...updateActiveStatus(prevShips, shipId),
      ...updatedShipDetails,
    }));
    setSelectedEvent((prevEvents: any) => ({
      ...prevEvents,
      ...updateActiveStatus(prevEvents, shipId),
    }));
  }

  const handleSatelliteImageSelect = async (object_id: string) => {
    const res = await axios.post(
      `${frontendAPIURL}/objects`,
      { id: "frontend", objects: [object_id] },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    const data = res.data[0];

    setMapViewState({
      latitude: data.latitude,
      longitude: data.longitude,
      zoom: 6,
    });

    if (data.attribution === "None") {
      handleNoAttributionShipSelect(data);
    } else if (data.attribution && !data.dark) {
      await handleAttributedObjectSelect(data, "light");
    } else if (data.attribution && data.dark) {
      await handleAttributedObjectSelect(data, "attributed");
    }
  };

  const loadShipsOnMap = async (isAISSearch: boolean, searchValue: ObjectSearchOptionResult | VesselSearcOptionResult) => {
    if (isAISSearch) {
      if (searchValue.synmax_ship_id) {
        await handleVesselShipSelect(searchValue.synmax_ship_id);
      }
    } else {
      if (searchValue.object_id) {
        await handleSatelliteImageSelect(searchValue.object_id);
      }
    }
  };

  const getNewOptions = async (searchValue: string) => {
    setLoadinSpinnerIsShown(true);
    searchTermRefValue.current = searchValue;
    const token = localStorage.getItem("token");

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}searchShips?q=${searchValue}&limit=${SEARCH_OPTIONS_LIMIT}&offset=0`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const searchReslutsFormatted: CurrentSearchResults = {
        vessels: res.data.vessels.results.map((item: any) => item.attributes),
        objects: res.data.objects.results.map((item: any) => ({
          object_id: item.object_id,
          ...item.attributes,
        })),
        vesselsTotal: res.data.vessels.paging.total,
        objectsTotal: res.data.objects.paging.total,
      };
      setCurrentSearchResults(searchReslutsFormatted);
      setLoadinSpinnerIsShown(false);
    } catch (err) {
      setLoadinSpinnerIsShown(false);
      console.log(err, "err");
    }
  };

  return {
    showResultsDialogIsOpen,
    setShowResultsDialogIsOpen,
    loadingSpinnerIsShown,
    selectedFilter,
    setSelectedFilter,
    searchDropdownIsOpen,
    setSearchDropdownIsOpen,
    currentSearchValue,
    setCurrentSearchValue,
    searchTermRefValue,
    currentSearchResults,
    setCurrentSearchResults,
    loadShipsOnMap,
    getNewOptions
  }
}

export default useSearch;