import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./MainSearch.module.scss";
import { debounce } from "lodash";
import SearchOptionRow from "./SearchOptionRow";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AllSearchResultsDialog from "./AllSearchResultsDialog";
import SynMaxIcon from "../../Shared/SynMaxIcon";
import CircularProgress from "@mui/material/CircularProgress";
import useSearch, {
  FilterType,
  ObjectSearchOptionResult,
  VesselSearcOptionResult,
  defaultOptions,
  initialSearchResultsState,
} from "./useSearch";

const filterOptions = (options: any, state: any): OptionType[] => {
  return options;
};

type OptionType = VesselSearcOptionResult | ObjectSearchOptionResult;

const MainSearch: React.FC<{
  searchIsExpanded: boolean;
  handleSearchExpand: (newState: boolean) => void;
}> = ({ searchIsExpanded, handleSearchExpand }) => {
  const {
    showResultsDialogIsOpen,
    setShowResultsDialogIsOpen,
    loadingSpinnerIsShown,
    selectedFilter,
    setSelectedFilter,
    searchDropdownIsOpen,
    setSearchDropdownIsOpen,
    currentSearchValue,
    setCurrentSearchValue,
    searchTermRefValue,
    currentSearchResults,
    setCurrentSearchResults,
    loadShipsOnMap,
    getNewOptions,
  } = useSearch();

  const handleCloseAllResultsDialog = (): void => {
    setShowResultsDialogIsOpen(false);
  };

  const getOptionsDelayed = useCallback(
    debounce((query) => {
      getNewOptions(query);
    }, 200),
    []
  );

  const currentSearchHasNoResults =
    currentSearchResults.vesselsTotal === 0 &&
    currentSearchResults.objectsTotal === 0;

  const getSearchInputEndAdronment = () => {
    if (currentSearchValue.length > 0 && !loadingSpinnerIsShown) {
      return (
        <IconButton
          aria-label="clear search value"
          onMouseDown={(event) => {
            event.preventDefault();
            setCurrentSearchValue("");
            setCurrentSearchResults(initialSearchResultsState);
          }}
          className={styles.searchClearButton}
        >
          <CloseIcon className={styles.searchClearIcon} fontSize="small" />
        </IconButton>
      );
    } else if (loadingSpinnerIsShown) {
      return <CircularProgress sx={{ color: "#ffffff" }} size={10} />;
    }
  };

  return (
    <div className={styles.mainSearchBar}>
      <Collapse
        orientation="horizontal"
        in={searchIsExpanded}
        collapsedSize={50}
        timeout={400}
      >
        <Autocomplete
          ListboxProps={{ style: { maxHeight: "450px", overflow: "hidden" } }}
          inputValue={currentSearchValue}
          id="main-search"
          options={
            selectedFilter.filterType === FilterType.Vessel
              ? currentSearchResults.vessels
              : currentSearchResults.objects
          }
          filterOptions={filterOptions}
          getOptionLabel={(option: any) => option.name}
          className={styles.searchAutoComplete}
          open={searchDropdownIsOpen}
          onOpen={() => {
            setSearchDropdownIsOpen(true);
          }}
          onClose={() => {
            setSearchDropdownIsOpen(false);
          }}
          onInputChange={async (event, newInputValue) => {
            if (event?.type === "change") {
              setCurrentSearchValue(newInputValue);
              if (newInputValue.length > 2) {
                getOptionsDelayed(newInputValue);
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search by name, IMO, ShipId, objectId or MMSI"
              variant="outlined"
              className={styles.searchTextField}
              onClick={() => {
                if (!searchIsExpanded) {
                  handleSearchExpand(true);
                }
              }}
              onBlur={() => {
                handleSearchExpand(false);
              }}
              onChange={() => {
                if (!searchIsExpanded) {
                  handleSearchExpand(true);
                }
              }}
              sx={{ input: { color: "#fff", width: "100%" } }}
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={styles.searchIcon}
                  >
                    <SearchOutlined
                      fontSize="small"
                      onClick={() => {
                        if (!searchDropdownIsOpen && searchIsExpanded) {
                          setSearchDropdownIsOpen(false);
                          handleSearchExpand(false);
                        } else if (searchDropdownIsOpen) {
                          setSearchDropdownIsOpen(false);
                          handleSearchExpand(false);
                        } else {
                          setSearchDropdownIsOpen(true);
                          handleSearchExpand(true);
                        }
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: getSearchInputEndAdronment(),
              }}
              fullWidth
            />
          )}
          renderOption={(props: any, value: any) => {
            if (selectedFilter.filterType === FilterType.Vessel) {
              return (
                <SearchOptionRow
                  key={JSON.stringify(value)}
                  searchValue={value}
                  isAISSearch={true}
                  loadShipsOnMap={loadShipsOnMap}
                  columns={[
                    { title: "Vessel Type", data: value.ship_type },
                    {
                      title: "IMO",
                      data: value.imo,
                    },
                    {
                      title: "MMSI",
                      data: value.mmsi,
                    },
                    { title: "Call Sign", data: value.name },
                    { title: "Length", data: value.length },
                    { title: "Width", data: value.width },
                  ]}
                  rowHeader={
                    <>
                      <SynMaxIcon icon="shipBow" size={20} />
                      <div className={styles.searchItemName}>{value.name}</div>
                      <img
                        src={`https://flagcdn.com/48x36/${value?.flag?.toLowerCase()}.png`}
                        width="30"
                        alt=""
                      />
                    </>
                  }
                  props={props}
                />
              );
            }
            if (selectedFilter.filterType === FilterType.Object) {
              return (
                <SearchOptionRow
                  key={JSON.stringify(value)}
                  isAISSearch={false}
                  searchValue={value}
                  loadShipsOnMap={loadShipsOnMap}
                  columns={[
                    { title: "Vessel Type", data: value.ship_type },
                    { title: "IMO", data: value.imo },
                    { title: "Status", data: value.status },
                    { title: "Provider", data: value.provider },
                    { title: "Length", data: value.length },
                    { title: "Width", data: value.width },
                  ]}
                  rowHeader={
                    <>
                      <SynMaxIcon icon="satellite" size={20} />
                      <div className={styles.searchItemName}>
                        {value.acquired}
                      </div>
                    </>
                  }
                  props={props}
                />
              );
            }
          }}
          PaperComponent={({ children }) => {
            return (
              <Paper
                sx={{
                  width: "100%",
                  backgroundColor: "#000000",
                  color: "#fff",
                }}
              >
                <div className={styles.searchHeader}>
                  {defaultOptions.map((option) => {
                    const selectedButtonColor =
                      selectedFilter === option
                        ? {
                            borderBottom: "2px solid #ffffff",
                            color: "#ffffff",
                          }
                        : { borderBottom: "1px solid gray", color: "gray" };
                    return (
                      <Button
                        key={option.filterType}
                        type="button"
                        variant="text"
                        className={styles.searchFilterButton}
                        sx={selectedButtonColor}
                        onMouseDown={(event) => {
                          event.preventDefault();
                          setSelectedFilter(option);
                        }}
                      >
                        {`${option.filterType} (${
                          option.filterType === "Vessels"
                            ? currentSearchResults.vesselsTotal
                            : currentSearchResults.objectsTotal
                        })`}
                      </Button>
                    );
                  })}
                  <div className={styles.headerDivider}></div>
                </div>
                {children}
                {currentSearchHasNoResults &&
                !loadingSpinnerIsShown &&
                currentSearchValue.length > 2 ? (
                  <div className={styles.noResultsFound}>
                    We were not able to find a match
                  </div>
                ) : null}
                {(selectedFilter.filterType === FilterType.Vessel &&
                  currentSearchResults.vessels.length > 0) ||
                (selectedFilter.filterType === FilterType.Object &&
                  currentSearchResults.objects.length > 0) ? (
                  <div className={styles.showAllResults}>
                    <Link
                      component="button"
                      variant="body2"
                      onMouseDown={(event) => {
                        event.preventDefault();
                        setShowResultsDialogIsOpen(true);
                      }}
                      className={styles.showResultsLink}
                    >
                      <div className={styles.showAllResultsText}>
                        See all results (
                        {selectedFilter.filterType === FilterType.Vessel
                          ? currentSearchResults.vesselsTotal
                          : currentSearchResults.objectsTotal}
                        )
                      </div>
                      <ArrowForwardIcon fontSize="small" />
                    </Link>
                  </div>
                ) : null}
              </Paper>
            );
          }}
        />
      </Collapse>
      <AllSearchResultsDialog
        showResultsDialogIsOpen={showResultsDialogIsOpen}
        handleCloseAllResultsDialog={handleCloseAllResultsDialog}
        currentSearchValue={currentSearchValue}
        filterOptions={defaultOptions}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        searchTermRefValue={searchTermRefValue.current}
        currentSearchCounts={{
          vesselsTotal: currentSearchResults.vesselsTotal,
          objectsTotal: currentSearchResults.objectsTotal,
        }}
        loadShipsOnMap={loadShipsOnMap}
      />
    </div>
  );
};

export default MainSearch;
