import { api } from "../../../services/api";
import { changeLoaderState } from "../../../redux/globalState.store";
import {
  setAISSTSEvents,
  setOpticalSTSEvents,
  setSanctionedShips,
  setSpoofingEvents,
  setDetections,
} from "../Theia.store";
import moment from "moment";
import { SatelliteObject } from "../../../types/types";

export const getAllInitialShips = async (
  token: string,
  setDetections: any,
  dispatch: any,
  date: any,
  isFirstLoad: boolean
) => {
  try {
    console.log("calling API");
    if (isFirstLoad) {
      dispatch(changeLoaderState(true));
    }
    const res = await api.get(
      `/ships/detections?date=${date.format("YYYY-MM-DD")}`
    );
    if (isFirstLoad) {
      dispatch(changeLoaderState(false));
    }
    dispatch(setDetections({ ...res.data.detections }));
    dispatch(setSpoofingEvents({ ...res.data.spoofingEvents }));
    dispatch(setAISSTSEvents({ ...res.data.AISSTS }));
    dispatch(setOpticalSTSEvents({ ...res.data.opticalSTSevents }));
    dispatch(setSanctionedShips({ ...res.data.sanctionedVessels }));
  } catch (err) {
    console.log(err);
    // Show snackbar on fail
  }
};

export const getShipInfo = async (shipids: string[], dateString: any) => {
  try {
    const res = await api.post("/shipInfo", { shipids, dateString });
    return { ...res.data };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const getShipAttributes = async (object_id: number) => {
  try {
    const res = await api.get(`/attributes/${object_id}`)
    return res.data;
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const updateShipAttributes = async (object_id: number, data: {}) => {
  try {
    const res = await api.put(`/attributes/${object_id}`, { data });
    return res;
  } catch (err) {
    console.log(err);
    throw new Error(err as any);
  }
};

export const searchShips = async (query: string) => {
  interface Ship {
    attributes: {
      name: string | null;
      synmax_ship_id: string;
      type: string;
      value: string | number;
    }
  }

  interface SearchOption {
    key: number;
    label: string;
    id: string;
    type: string;
    value: string | number;
  }


  try {
    const res = await api.get(`/searchShips?q=${query}`);
    // console.log(res);

    const searchOptions: SearchOption[] = res.data.vessels.results
      .filter((ship: Ship) => ship.attributes.name && ship.attributes.name !== "nan")
      .map(({ attributes: { name, synmax_ship_id, type, value } }: Ship, i: number) => ({
        key: i,
        label:
          name +
          (type === 'mmsi' ? ` (${type} :${value})` : '') +
          (type === 'imo' ? ` (${type} :${value})` : ''),
        id: synmax_ship_id,
        type,
        value,
      }));

    return searchOptions;
  } catch (err) {
    console.log(err);
    throw new Error(err as any);
  }
};

export const getObjectsForShips = async (
  shipids: string[],
  startDate?: string,
  endDate?: string
): Promise<SatelliteObject[]> => {
  try {
    const id = "frontend";
    const start = startDate || "2021-01-01 00:00:00";
    const end = endDate || moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const res = await api.post("/shipImages/shipObjects", {
      id,
      start,
      end,
      shipids,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getSatelliteImage = async (objectId: number) => {
  try {
    const response = await api.get(
      `/shipImages/shipClip?object_id=${objectId}`,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = URL.createObjectURL(blob);
    return url;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getEvents = async ({
  event_types,
  area = null,
  ship_ids = null,
  object_ids = null,
  start_date = null,
  end_date = null,
  deduplicate_sts = false,
  risk_level = null,
}: {
  event_types: string[];
  area?: object | null;
  ship_ids?: string[] | null;
  object_ids?: number[] | null;
  start_date?: string | null;
  end_date?: string | null;
  deduplicate_sts?: boolean;
  risk_level?: string | null;
}) => {
  try {
    const start =
      start_date || event_types.includes("ais-port-calls")
        ? moment.utc().subtract(30, "days").format("YYYY-MM-DD HH:mm:ss")
        : "2021-01-01 00:00:00";
    const end = end_date || moment.utc().format("YYYY-MM-DD HH:mm:ss");

    const response = await api.post("/getEvents", {
      event_types,
      area,
      ship_ids,
      object_ids,
      start_date: start,
      end_date: end,
      deduplicate_sts,
      risk_level,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    return {};
  }
};

function randomInteger(min: any, max: any) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getSanctionVessels = async () => {
  try {
    const response = await api.get("/sanctions/sanctioned_vessels");
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getSanctionDetails = async (shipId: string) => {
  try {
    const response = await api.get(`/sanctions?synmax_ship_id=${shipId}`);
    return { ...response.data };
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getShipPath = async (
  shipids: string[],
  start: string,
  end: string
) => {
  try {
    const res = await api.post("/ais_for_ships", { shipids, start, end });
    return res.data.sort(compare);
  } catch (err) {
    console.log(err);
    return [];
  }
};

function compare(a: { timestamp: number }, b: { timestamp: number }): number {
  if (a.timestamp < b.timestamp) {
    return -1;
  }
  if (a.timestamp > b.timestamp) {
    return 1;
  }
  return 0;
}

export const getShipObject = async (object: number[]) => {
  try {
    const res = await api.post("/object", { object });
    return res.data[0];
  } catch (err) {
    return [];
  }
};

export const getSimilarShips = async (
  shipId: string,
  start: string,
  end: string,
  area: any
) => {
  try {
    const res = await api.post("/ship_search", { shipId, start, end, area });
    return res.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
